import React, { useState } from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink, } from '@apollo/client';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import Home from './pages/Home';
import Payment from './pages/Payment';
import Order from './pages/Order';
import Footer from './components/Footer';
import Error07 from './pages/Error07';
import OrderUpdateErr from './pages/OrderUpdateErr';
import Confirmation from './pages/Confirmation';
import Header from './components/Header';
import MainHome from './pages/MainHome';
import Fresh from './pages/FreshProduce';
import NotFound from './components/NotFound';
// import Test from './pages/Test'
import "./style.css"

const httpLink = createHttpLink({
  uri: '/graphql',
});

const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});

function App() {
  const [order, setorder]=useState(' ')
  const [TotalOrder, setTotalOrder]=useState('')
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');
  const [myemail, setmyemail] = useState('');
  const [myaddress, setmyaddress] = useState('');
  const [mypostalcode, setpostalcode] = useState('');
  const [myname, setmyname] = useState('');
  const [myphone, setmyphone] = useState('');
  const [orderDescription, setOrderDescription] = useState('');
  const [isFilled, setIsFilled]=useState(false)
  const [selectedCity, setSelectedCity] = useState("");
  const [deliveryFee, setDeliveryFee]=useState('')
  const [subTotal, setSubtotal]=useState('')
  const [totalBeforeTaxes, setTotalBeforeTaxes] = useState('');
  const [taxes, setTaxes] = useState('');
  const [howdelivery, setHowDelivery] = useState('')
  const [objDate, setObjDate] = useState('')
  const [keyppl, setKey]=useState('')
  const [dlvdates, setdlvdates]=useState('')
  const [pcpdates, setpcpddates]=useState('')
  const[cakeson,setcakeson]=useState('')
// const[tamalon,settamalon]=useState('')

  return (
    <ApolloProvider client={client}>
      
      <Router>
        <main>
          <Header/>
          <div className='maincontent'> 
            <Routes> 
              <Route 
                path="/" 
                element={<MainHome 
                />} 
              />
              <Route 
                path="/fruits_vegetables_dist" 
                element={<Fresh
                />} 
              />
              <Route 
                path="/cakes_desserts_main" 
                element={<Home 
                setorder={setorder} 
                subTotal={subTotal} 
                setSubtotal={setSubtotal}
                setOrderDescription={setOrderDescription}
                setdlvdates={setdlvdates}
                setpcpddates={setpcpddates}
                cakeson={cakeson}
                // tamalon={tamalon}
                setcakeson={setcakeson}
                // settamalon={settamalon}
                />} 
              />
              <Route 
                path="/Order" 

                element={<Order
                  // cakeson={cakeson}
                  // tamalon={tamalon}
                  dlvdates={dlvdates}
                  pcpdates={pcpdates}
                howdelivery={howdelivery}
                setHowDelivery={setHowDelivery} 
                setKey={setKey}
                keyppl={keyppl}
                order={order}
                TotalOrder={TotalOrder}
                selectedDate={selectedDate}
                selectedTime={selectedTime}
                myname={myname}
               objDate = {objDate}
               setObjDate={setObjDate}
                deliveryInstructions={deliveryInstructions} 
                myemail={myemail} 
                myphone={myphone}
                myaddress={myaddress}
                setSelectedDate={setSelectedDate} 
                setDeliveryInstructions={setDeliveryInstructions} 
                setSelectedTime={setSelectedTime}
                setmyemail={setmyemail}
                setmyname={setmyname}
                setmyphone={setmyphone}
                setmyaddress={setmyaddress}
                mypostalcode={mypostalcode}
                setpostalcode={setpostalcode}
                isFilled={isFilled}
                setIsFilled={setIsFilled}
                selectedCity={selectedCity}
                setSelectedCity={setSelectedCity}
                deliveryFee={deliveryFee}
                setDeliveryFee={setDeliveryFee}
                subTotal={subTotal}
                setSubtotal={setSubtotal}
                setTotalOrder={setTotalOrder}
                totalBeforeTaxes={totalBeforeTaxes}
                setTotalBeforeTaxes={setTotalBeforeTaxes}
                taxes={taxes}
                setTaxes={setTaxes}           
                />} />
        
              <Route 
                path="/ProcessPayment" 
                element={<Payment 
                isFilled={isFilled} 
                  keyppl={keyppl}
                  howdelivery={howdelivery}
                  objDate = {objDate}
                  setObjDate={setObjDate}
                  order={order}
                  TotalOrder={TotalOrder}
                  orderDescription={orderDescription}
                  selectedDate={selectedDate} 
                  deliveryInstructions={deliveryInstructions} 
                  selectedTime={selectedTime}
                  myemail={myemail} 
                  myaddress={myaddress}
                  mypostalcode={mypostalcode}
                  myname={myname}
                  myphone={myphone}
                  setTotalOrder={setTotalOrder}
                  setSelectedDate={setSelectedDate} 
                  setDeliveryInstructions={setDeliveryInstructions} 
                  setSelectedTime={setSelectedTime}
                  setmyemail={setmyemail}
                  setmyname={setmyname}
                  setmyphone={setmyphone}
                  setmyaddress={setmyaddress}
                  setpostalcode={setpostalcode}
                  setIsFilled={setIsFilled}
                  selectedCity={selectedCity}
                  setSelectedCity={setSelectedCity}
                  deliveryFee={deliveryFee}
                  setDeliveryFee={setDeliveryFee}
                  subTotal={subTotal}
                  setSubtotal={setSubtotal}
                  totalBeforeTaxes={totalBeforeTaxes}
                  setTotalBeforeTaxes={setTotalBeforeTaxes}
                  taxes={taxes}
                  setTaxes={setTaxes}         
               
                />} 
              />

<Route 
                path="/error07" 
                element={<Error07
                  setSubtotal={setSubtotal}
                  setTotalOrder={setTotalOrder}
                  setorder={setorder}
                  setSelectedDate={setSelectedDate} 
                  setSelectedTime={setSelectedTime}
                  setmyemail={setmyemail}
                  setmyname={setmyname}
                  setmyphone={setmyphone}
                  setmyaddress={setmyaddress}
                  setIsFilled={setIsFilled} 
                  />} 
              
              />
<Route 
                path="/UpdateError" 
                element={<OrderUpdateErr
                  setSubtotal={setSubtotal}
                  setTotalOrder={setTotalOrder}
                  setorder={setorder}
                  setSelectedDate={setSelectedDate} 
                  setSelectedTime={setSelectedTime}
                  setmyemail={setmyemail}
                  setmyname={setmyname}
                  setmyphone={setmyphone}
                  setmyaddress={setmyaddress}
                  setIsFilled={setIsFilled} 
                />} 
              
              />
                    <Route 
                path="/:orderId" 
                element={<Confirmation 
                  setSubtotal={setSubtotal}
                  setTotalOrder={setTotalOrder}
                  setorder={setorder}
                  setSelectedDate={setSelectedDate} 
                  setSelectedTime={setSelectedTime}
                  setmyemail={setmyemail}
                  setmyname={setmyname}
                  setmyphone={setmyphone}
                  setmyaddress={setmyaddress}
                  setIsFilled={setIsFilled} 
                />
              } 
              />
           <Route path="*" element={<NotFound />} />
              
            </Routes>
          </div>
          <Footer />
        </main>
      </Router>
    </ApolloProvider>
  );
}


export default App;
