import React from 'react';
import image1 from '../components/img/Bytelandialogo3.png'
import image111 from '../components/img/logo.png'
import { Link } from 'react-scroll';
import { Link as ScrollLink} from 'react-scroll';
import { useLocation } from 'react-router-dom';


const Header = () => {

  const location = useLocation();

  return (
    <div className={location.pathname !== '/fruits_vegetables_dist'&&location.pathname !== '/'?'headercomp':'headercomp2'}>
{location.pathname === '/cakes_desserts_main'&&(<ul className='navtabs'>
      <ScrollLink
      style={{ cursor: 'pointer'}}
      className="links"
      to="Cakes"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Cakes
      </ScrollLink>
      <ScrollLink
      style={{ cursor: 'pointer'}}
      className="links"
      to="Cookies"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Cookies
      </ScrollLink>
      <ScrollLink
      style={{ cursor: 'pointer'}}
      className="links"
      to="Deserts"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Deserts
      </ScrollLink>
      {/* <ScrollLink
      style={{ cursor: 'pointer'}}
      className="links"
      to="Tamal"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Tamal
      </ScrollLink> */}
      <ScrollLink
      style={{ cursor: 'pointer'}}
      className="links"
      to="Order"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Order
      </ScrollLink>
      {/* <ScrollLink
      className="links"
      style={{ cursor: 'pointer'}}
      to="About"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      About Us
      </ScrollLink> */}
    </ul>)}

       {location.pathname === '/'&& (
        <div className='imgheader111'>
        <img className='imgh111' src={image111} alt="logo"></img>
        
        
        </div>
       ) }


       {location.pathname !== '/'&&location.pathname !== '/fruits_vegetables_dist'&& (
       <div className='imgheader'>
       <img className='imgh' src={image1} alt="logo"></img>
       
       
       </div>
       )}

       {location.pathname === '/cakes_desserts_main' &&(
        <div className='navlinks' style={{ zIndex: 2000, }}>
          <div className='menuswitch'><h1>&#9776;</h1></div>
          <ul className='nvul'>
   
      <Link
      style={{ cursor: 'pointer'}}
      className="links"
      to="Cakes"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Cakes
      </Link>
      <Link
      style={{ cursor: 'pointer'}}
      className="links"
      to="Cookies"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Cookies
      </Link>
      <Link
      style={{ cursor: 'pointer'}}
      className="links"
      to="Deserts"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Deserts
      </Link>
      {/* <Link
      style={{ cursor: 'pointer'}}
      className="links"
      to="Tamal"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Tamal
      </Link> */}
      <Link
      style={{ cursor: 'pointer'}}
      className="links"
      to="Order"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      Order
      </Link>
      {/* <Link
      className="links"
      style={{ cursor: 'pointer'}}
      to="About"
      spy={true}
      smooth={true}
      offset={-70}
      duration={500}
      >
      About Us
      </Link> */}
    </ul>
      
    </div>
     )}
    

    </div>
  );
};

export default Header;