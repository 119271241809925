import React from 'react';
import Canonical from '../components/Canonical';
import FPcont from '../components/Distributor/FPInt';
import image111 from '../components/img/Bytelandialogo3.png';
import image112 from '../components/img/maillogo.png';
import image113 from '../components/img/whatsapplogopink.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const FreshProduce = () => {
  const cookiespics= ['https://storage.googleapis.com/bite_imp_images/granadilla-producto-caribbean-exotics-1.png',
    'https://storage.googleapis.com/bite_imp_images/engin-akyurt-uDtO1mko__w-unsplash.jpg'];


  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 1000, // Transition speed
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll at a time
    swipeToSlide: true, // Allow swiping to navigate
    touchMove: true, // Enable touch gestures
    autoplay: true, // Auto-play the carousel
    autoplaySpeed: 7000,
  };

  const handleRedirectPayment = () =>{
    window.location.replace('/')
 
   }

  return (
    <div className='home'>
      <Canonical url="https://www.bitelandia.com/fruits_vegetables_dist" />

<div className='headerFP'>
  <div className='fivimgFP' >
  <img src={image111} alt='logo' className='logo' />
  </div>

  <div className='divcontactFP'>
    <div className='divFPmail'>
    <a href="mailto:sales@bitelandia.com"><img src={image112} alt='mail' className='mail' /></a>
    </div>
    <div className='divFPWs'>
    <a href='https://wa.me/16478718533' target='_blank' rel='noreferrer' ><img src={image113} alt='whatsapp' className='whatsapp' /></a>
    </div>
    </div>
</div>






      <div class='fpcont'>
               <div className='cakescontent'>
  <div className='newingredients'>
    
    
    <h1>
      Distribution of Fresh Produce
      </h1>
      
      
   
    </div>
    <div className='divparopening'>
    <div className='paropening'>
    <FPcont/>
    </div>
    </div>


  </div>
      </div>
      <div className='btncenter'>
    <button className='proceedBtn'
     style={{ cursor: 'pointer',  }}
     onClick={handleRedirectPayment}
     >
     Go home
     </button>
    </div>
      <div className='centecontainerfp'>
      {cookiespics.length>0&&<div className='containercarrousel2'>

<Slider {...settings} className="mycarrousel">
{cookiespics.map((image, index) => (
   <div key={index}className='carousel-slide'>
  <img  className='imgcarrousel2'src={image} alt={`COOKIE ${index}`} />
   
    
  
   </div>
))}
</Slider>

</div>}
      </div>



    </div>
  );
};

export default FreshProduce;
