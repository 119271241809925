
import React from 'react';
import { useState, useEffect} from 'react';

const About = () => 
{
  const [text, setText] = useState(false)
  const [classmore, setTextmore] = useState("read-more-less");

  useEffect(() => {
    if(text === false) {
      setTextmore("read-more-less");
    } else {
      setTextmore("nodisplay-more");
    }
  }, [text]);
    return (

        <div>
    
      <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
      Bitelandia began as an online bakery with a mission to deliver healthy, wholesome foods to clients in the Greater Toronto Area. 
        <span>
          <button className={classmore} onClick={() => setText(!text)}>..read more</button>
        </span><br></br>
       
      </p>
      {text && (
        <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
Based in Oakville, Ontario, Canada, our passion for promoting healthier lifestyles inspired us to explore new opportunities in the food industry. As a result, we expanded our business to include the importation of fresh avocados and other premium fruits and vegetables, providing bulk orders to large businesses.

In our bakery line, we prioritize the use of gluten-free flour and low-calorie sweeteners, ensuring that our products meet the needs of health-conscious consumers. At Bitelandia, we remain committed to our founding mission: delivering high-quality, nutritious food options to individuals and businesses alike.
          <span>
          <button className="read-more-less" onClick={() => setText(false)}>..read less</button>
          </span><br></br>
        </p>
      )}
        </div>
    )
}

export default About;

