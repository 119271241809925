
import React from 'react';


const About = () => 
{

  
    return (

        <div>
    
      <p style={{ textAlign: 'justify', marginRight:"5px", fontWeight: "normal" }}>
      If your business, whether large or small, is seeking a reliable supplier of avocados, granadillas, and other fresh produce, Bitelandia is here to meet your needs. We specialize in bulk orders of premium-quality fruits and vegetables and are licensed importers of Hass avocados and granadillas, sourced directly from the tropical fields of Central and South America.

Our commitment is to deliver fresh, high-quality produce to your warehouse, anywhere across Canada. Please feel free to contact us via email to discuss your specific requirements. We look forward to partnering with you to support your business.
        </p>

        </div>
    )
}

export default About;

